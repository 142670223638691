button {
    /* width: 200px; */
    max-width: 100%;
    box-sizing: border-box;
    background-color: var(--accent);
    border: none;
    border-radius: var(--radius);
    padding: var(--medspace);
    padding-left: var(--bigspace);
    padding-right: var(--bigspace);
    margin: var(--bigspace);
    margin-bottom: 0;
    color: var(--buttontextcolour);
    cursor: pointer;
    outline: none;
    transition: var(--veryshortanim);
}

button > * {
    transition: var(--veryshortanim);
}

button:hover {
    background-color: var(--accent2);
}
