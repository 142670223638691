@import url(https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: var(--bg);
}
/*noinspection CssUnknownTarget*/

* {
    /* colours */
    --bg: hsl(210, 17%, 98%);
    --solid: white;
    --textcolour: hsl(0, 0%, 14%);
    --textalt: hsl(0, 0%, 40%);
    --accent: hsl(43, 80%, 65%);
    --accent2: hsl(43, 100%, 90%);
    --accent3: hsl(43, 50%, 25%);
    --error: hsl(0, 100%, 30%);
    --buttontextcolour: var(--textcolour);
    
    /* borders */
    --radius: 8px;
    --border: 1px solid hsl(220, 9%, 87%);
    
    /* lengths */
    --hugespace: 50px;
    --vbigspace: 30px;
    --bigspace: 18px;
    --medspace: 10px;
    --smallspace: 5px;
    --bigheight: 80px;
    --medheight: 50px;
    --smallheight: 40px;
    --inputWidth: 300px;
    --maincontent: 800px;
    --logoWidth: 138px;

    /* animation */
    --longanim: 2s;
    --shortanim: 0.5s;
    --veryshortanim: 0.2s;

    font-family: 'Lexend Deca', sans-serif;
    font-size: medium;
}

label.input {
    display: block;
    color: var(--textcolour);
    padding: var(--medspace);
    cursor: pointer;
}

input.input, textarea.input {
    display: block;
    color: var(--textcolour);
    padding: var(--medspace);
    border: var(--border);
    border-radius: var(--radius);
    width: 100%;
    max-width: var(--inputWidth);
    box-sizing: border-box;
    transition: var(--shortanim);
    outline: none;
    font-family: 'Lexend Deca', sans-serif;
    font-size: medium;
    margin-bottom: var(--bigspace);
}

textarea.input {
    max-width: 100%;
}

/* input.input:invalid {
    border: 1px red solid;
} */

label.radio, label.checkbox {
    cursor: pointer;
    margin-right: var(--medspace);
}

input.radio, input.checkbox {
    margin-left: var(--medspace);
    margin-right: var(--smallspace);
    cursor: pointer;
}

span.radio, span.checkbox {
    display: inline-block;
}

.radiogroup, .checkboxgroup {
    margin-top: var(--bigspace);
    margin-bottom: var(--bigspace);
}

h1 {
    font-size: larger;
    font-weight: normal;
    color: var(--textcolour);
    /* padding-left: var(--medspace);
    padding-right: var(--medspace); */
}

h1.nopad {
    padding: 0;
}

h1.huge {
    font-size: xx-large;
}

h1.xhuge {
    font-size: 45px;
    color: inherit;
    margin: 0;
}

h1.xxhuge {
    font-size: 60px;
    color: inherit;
}

table td, table th {
    padding: var(--bigspace);
    text-align: left;
}

html {
    height: 100%;
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
}

body {
    overflow-x: hidden;
    height: 100%;
}

#root {
    height: 100vh;
}

.selectable {
    user-select: all; /* supported by Chrome and Opera */
   -webkit-user-select: all; /* Safari */
   -moz-user-select: all; /* Firefox */
   -ms-user-select: all; /* Internet Explorer/Edge */
}

.fullscreen {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
}

.hidden {
    display: none;
    transition: 1s;
}

.maxwidth {
    width: 100%;
}

.autoscroll {
    overflow-y: auto;
}

.overflow-x {
    overflow-x: auto;
}

.noclick {
    pointer-events: none;
}

.centered {
    text-align: center;
}

.pointer {
    cursor: pointer;
}

.block {
    display: block;
}

.blocklink {
    cursor: pointer;
    text-decoration: underline;
    display: block;
    padding-top: var(--smallspace);
    padding-bottom: var(--smallspace);
}

.nowrap {
    white-space: nowrap;
}

.margin-auto {
    margin: auto;
}

.capitalize {
    text-transform: capitalize;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.underline {
    text-decoration: underline;
}

div.medspace {
    height: var(--medspace);
}

div.bigspace {
    height: var(--bigspace);
}

div.vbigspace {
    height: var(--vbigspace);
}

div.bigheight {
    height: var(--bigheight);
}

div.medheight {
    height: var(--medheight);
}

div.smallheight {
    height: var(--smallheight);
}

.absolute {
    position: absolute;
}

.shadow {
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.3);
}

.bigshadow {
    box-shadow: 0 3px 60px 0 rgba(0, 0, 0, 0.6)
}

.aligntop {
    vertical-align: top;
}

.justify {
    text-align: justify;
    text-justify: inter-word;
}

@media screen and (max-width: 400px) {
    .myaccount {
        width: auto;
        width: initial;
    }

    .myaccount table {
        margin: 0;
        margin: initial;
    }
    
    .myaccount table td {
        display: block;
    }
    
    .edituser td {
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .donationform td {
        display: block;
    }

    .hide-on-mobile {
        display: none !important;
    }
}

.side-by-side {
    display: grid;
    grid-template-areas: "left right";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.side-by-side > .left-half {
    grid-area: left;
    padding: var(--medspace);
}

.side-by-side > .right-half {
    grid-area: right;
    padding: var(--medspace);
}

@media screen and (max-width: 800px) {
    .side-by-side {
        grid-template-areas: "left" "right";
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        margin: auto;
    }

    .side-by-side > .left-half {
        max-width: 400px;
    }

    .side-by-side > .right-half {
        max-width: 400px;
    }
}

@media screen and (max-width: 850px) {
    /* if width changed also change js in purefunctions.tsx */
    .home .main {
        min-width: 100vw;
    }
}

.small-nav {
    display: none;
}

.large-nav {
    display: inline;
    display: initial;
}

@media screen and (max-width: 900px) {
    .small-nav {
        display: inline;
        display: initial;
    }

    .large-nav {
        display: none;
    }
}

.error {
    padding: 10px;
    border-radius: 10px;
}

.grid3x3 {
    display: grid;
    grid-template-areas: 
        "a1 a2 a3"
        "a4 a5 a6"
        "a7 a8 a9";
    grid-template-columns: 1fr auto 1fr;
}

.grid3x3 > * {
    grid-area: a5;
    place-self: center;
}

.grid3x3 > a1, .grid3x3 > .a1 {grid-area: a1;}
.grid3x3 > a2, .grid3x3 > .a2 {grid-area: a2;}
.grid3x3 > a3, .grid3x3 > .a3 {grid-area: a3;}
.grid3x3 > a4, .grid3x3 > .a4 {grid-area: a4;}
.grid3x3 > a5, .grid3x3 > .a5 {grid-area: a5;}
.grid3x3 > a6, .grid3x3 > .a6 {grid-area: a6;}
.grid3x3 > a7, .grid3x3 > .a7 {grid-area: a7;}
.grid3x3 > a8, .grid3x3 > .a8 {grid-area: a8;}
.grid3x3 > a9, .grid3x3 > .a9 {grid-area: a9;}
.grid1x3 {
    display: grid;
    grid-template-areas: "a1" "a2" "a3";
    grid-template-rows: 1fr auto 1fr;
}

.grid1x3 > * {
    grid-area: a2;
}

.grid1x3 > a1, .grid1x3 > .a1 {grid-area: a1;}
.grid1x3 > a2, .grid1x3 > .a2 {grid-area: a2;}
.grid1x3 > a3, .grid1x3 > .a3 {grid-area: a3;}
.grid3x1 {
    display: grid;
    grid-template-areas: "a1 a2 a3";
    grid-template-columns: 1fr auto 1fr;
}

.grid3x1 > * {
    grid-area: a2;
}

.grid3x1 > a1, .grid3x1 > .a1 {grid-area: a1;}
.grid3x1 > a2, .grid3x1 > .a2 {grid-area: a2;}
.grid3x1 > a3, .grid3x1 > .a3 {grid-area: a3;}
.login {
    width: calc(var(--inputWidth) + 2 * var(--bigspace));
    max-width: 100vw;
    padding: var(--bigspace);
    border: var(--border);
    border-radius: 10px;
    background-color: var(--solid);
    box-sizing: border-box;
    transition: var(--longanim);
}

.login-logo {
    width: 200px;
    max-width: 100%;
    padding: var(--hugespace);
}

.forgot {
    text-decoration: underline;
    cursor: pointer;
    padding: var(--smallspace);
}

.login .error {
    color: var(--error);
}

button {
    /* width: 200px; */
    max-width: 100%;
    box-sizing: border-box;
    background-color: var(--accent);
    border: none;
    border-radius: var(--radius);
    padding: var(--medspace);
    padding-left: var(--bigspace);
    padding-right: var(--bigspace);
    margin: var(--bigspace);
    margin-bottom: 0;
    color: var(--buttontextcolour);
    cursor: pointer;
    outline: none;
    transition: var(--veryshortanim);
}

button > * {
    transition: var(--veryshortanim);
}

button:hover {
    background-color: var(--accent2);
}


.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 30px;
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  .lds-ellipsis div {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--buttontextcolour);
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
  }
  @-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
  }
  @-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
  }
  
.card.newcampaign .solid .middle {
    width: auto;
}

.card {
    margin-bottom: var(--bigspace);
}

.card .solid {
    padding: var(--bigspace);
    background-color: var(--solid);
    border: var(--border);
    border-top: none;
    border-radius: 0 0 var(--radius) var(--radius);
}

.card .solid .middle {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 100%;
    margin: auto;
    overflow-x: auto;
}

.card .top {
    background-color: var(--accent2);
    padding: var(--bigspace);
    border: var(--border);
    border-radius: var(--radius) var(--radius) 0 0;
}

.card .top h1 {
    margin: 0;
    color: var(--textcolour);
}


.title-with-back img {
    width: 12px;
    padding-right: var(--smallspace);
}

.title-with-back h1 {
    display: inline-block;
}

.title-with-back {
    cursor: pointer;
}

.title-with-back:hover {
    font-weight: bolder;
}

.changepassword {
    padding: var(--medspace);
}

.changepasswordinputs {
    padding: var(--bigspace);
    background-color: var(--solid);
    border: var(--border);
    border-radius: var(--radius);
}

.changepasswordinputs > * {
    margin: auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.changepassword .error {
    color: var(--error);
    padding: var(--smallspace);
}

.accountcircle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: var(--bigspace);
    background-color: var(--accent2);
    cursor: pointer;
}

.accountletter {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 70px;
    cursor: pointer;
}


.edituser .registration {
    word-break: break-all;
}

.edituser td {
    padding: var(--medspace);
}

.edituser .email {
    padding: var(--medspace);
}

img.profile {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: var(--bigspace);
    object-fit: cover;
    cursor: pointer;
}

.userlist {
    padding: var(--bigspace);
    background-color: var(--solid);
    border: var(--border);
    border-radius: var(--radius);
    overflow-x: auto;
}

.manageusers tbody tr:hover {
    background-color: var(--accent2);
    cursor: pointer;
    transition: var(--veryshortanim);
}

.manageusers .edit img {
    height: 16px;
}

.manageusers td, .manageusers th {
    white-space: nowrap;
}
.newuser .link {
    word-break: break-all;
}

body {
    --navwidth: 250px;
    --navOffset: 0px;
}

.home {
    display: grid;
    grid-template-columns: var(--bigheight) calc(var(--navwidth) - var(--bigheight)) auto;
    grid-template-rows: var(--bigheight) auto;
    grid-template-areas: 'burger title title' 'nav nav main';
    /* transition: var(--longanim); */
}

.home .titlebar {
    grid-area: title;
    width: 100%;
    height: 100%;
    background-color: var(--solid);
    border-bottom: var(--border);
    box-sizing: border-box;
}

.home .burgerarea {
    width: 100%;
    height: 100%;
    grid-area: burger;
    background-color: var(--solid);
    border-bottom: var(--border);
    box-sizing: border-box;
}

.home .hamburger {
    height: 20px;
    padding: 15px;
}

.home .burgerbutton {
    display: inline-block;
    height: var(--medheight);
    width: var(--medheight);
    border-radius: 50%;
    cursor: pointer;
    transition: var(--veryshortanim);
}

.home .burgerbutton:hover {
    background-color: var(--accent2);
}

.home .logo {
    width: 138px;
    padding: var(--bigspace);
}

.home .nav {
    grid-area: nav;
    padding-top: var(--medspace);
    -webkit-transform: translate(var(--navOffset), 0px);
            transform: translate(var(--navOffset), 0px);
    transition: var(--shortanim);
    overflow-y: auto;
}

.home .navitem {
    margin: var(--medspace);
    margin-left: 0;
    padding-left: var(--bigspace);
    height: var(--medheight);
    border-radius: 0 100vw 100vw 0;
    cursor: pointer;
    transition: var(--veryshortanim);
}

.home .navitem:hover {
    background-color: var(--accent2);
}

.home .navitem.selected {
    background-color: var(--accent2);
    font-weight: bold;
}

.home .main {
    grid-area: main;
    padding: var(--bigspace);
    box-sizing: border-box;
    margin-left: var(--navOffset);
    transition: var(--shortanim);
    overflow-y: auto;
}

.home .maincontent {
    max-width: var(--maincontent);
    margin: auto;
    padding-bottom: var(--bigheight);
}


.campaign tbody tr:hover {
    background-color: var(--accent2);
    cursor: pointer;
    transition: var(--veryshortanim);
}

img.campaign-picture {
    width: 300px;
    display: block;
    margin: var(--bigspace);
}

.edit-assessment a {
    text-decoration: none;
}

.assessment-picture {
    width: 500px;
    max-width: 100%;
    display: block;
    border-radius: 10px;
}

.open-document {
    border: 1px solid black;
    padding: 20px;
    border-radius: 10px;
    color: black;
}

.registeruser {
    padding: var(--bigspace);
    background-color: var(--solid);
    border: var(--border);
    border-radius: var(--radius);
    max-width: 100vw;
    width: calc(var(--inputWidth) + 2 * var(--bigspace));
    box-sizing: border-box;
}

.registeruser-error {
    max-width: var(--inputWidth);
    padding: var(--medspace);
}

.connectiondetector {
    position: fixed;
    pointer-events: none;
    width: 100%;
}

.connectiondetector .text {
    text-align: center;
    width: 100%;
    max-width: 300px;
    padding: var(--bigspace);
    background-color: var(--accent2);
    border: var(--border);
    border-radius: var(--radius);
    margin: var(--bigspace);
    box-sizing: border-box;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
}

.userguide {
    max-width: 600px;
    width: 100vw;
    padding: var(--medspace);
    padding-top: var(--hugespace);
    padding-bottom: var(--hugespace);
    box-sizing: border-box;
    word-break: break-word;
}

.userguide img {
    width: 100%;
    padding: var(--bigspace);
    box-sizing: border-box;
}

.collapsible h1 {
    cursor: pointer;
    padding: 0;
}

.collapsible .children {
    padding: var(--bigspace);
}

.donationform {
    max-width: var(--maincontent);
    padding-top: var(--hugespace);
    padding-bottom: var(--bigheight);
    margin: var(--bigspace);
}

.donationform table.nopad td {
    padding: 0;
}

.donationform table input.input {
    margin: 0;
}

.donationform table h1 {
    margin-right: var(--bigspace);
}

.donationform .line {
    background-color: var(--accent);
    height: 1px;
    margin-top: var(--bigspace);
    margin-bottom: var(--bigspace);
}

.addressfinder {
    margin-bottom: var(--medspace);
}

.addressfinder .link {
    text-decoration: underline;
    cursor: pointer;
    padding: var(--medspace);
}

.addressfinder .message {
    padding: var(--medspace);
}

.landingpage {
    width: 100vw;
    overflow-x: hidden;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
}

.landingpage .body > * {
    position: relative;
}

.landingpage .body .centre {
    max-width: var(--maincontent);
    margin: auto;
    padding: var(--bigspace);
}

.landingpage .halfwidth {
    max-width: calc(var(--maincontent) / 2);
    display: inline-block;
}

.landingpage .image {
    position: absolute;
    width: 100vw;
    background-repeat: no-repeat;
    background-attachment: local;
    background-position: center;
}

.landingpage .image.main {
    margin-top: 100px;
    height: 700px;
    background-size: 1000px;
}

.landingpage .image.bg1 {
    height: 700px;
    background-size: 4000px;
}

.landingpage .image.bg2 {
    height: 930px;
    background-size: 4000px;
}

.landingpage .image.bg3 {
    margin-top: -1px;
    height: 130px;
    background-size: 4000px;
}

.landingpage .image.bg4 {
    margin-top: -1px;
    height: 160px;
    background-size: 4000px;
}

.landingpage .image.bg5 {
    margin-top: -5px;
    height: 470px;
    background-size: 4000px;
}

.landingpage .image.bg6 {
    margin-top: -100px;
    height: 460px;
    background-size: 4000px;
}

.landingpage .image.bg7 {
    height: 1020px;
    background-size: 4000px;
}

/* main picture */
.landingpage .body .space1 {
    height: 750px;
}

/* devices */
.landingpage .body .space2 {
    height: 630px;
}

.landingpage .body .space3 {
    height: 120px;
}

.landingpage .body .space4 {
    height: 130px;
}

.landingpage .body .space5 {
    height: 50px;
}

.landingpage .body .space6 {
    height: 350px;
}

.landingpage .body .dark {
    background-color: var(--textcolour);
    color: var(--bg);
}

.landingpage .body .accented {
    background-color: var(--accent);
}

.landingpage .box {
    height: 200px;
    width: 300px;
    display: inline-grid;
    margin: var(--medspace);
    grid-template-rows: auto -webkit-max-content;
    grid-template-rows: auto max-content;
}

.landingpage .box .icon {
    margin: auto;
    width: 100px;
}

.landingpage .box .text {
    text-align: center;
}

.landingpage .devices {
    max-width: 500px;
    margin: auto;
}

.landingpage img.tablet {
    position: absolute;
    margin-left: 60px;
    width: 400px;
    border-radius: 25px;
    border: 15px solid hsl(0, 0%, 20%);
}

.landingpage img.phone {
    position: absolute;
    margin-top: 130px;
    width: 200px;
    border-radius: 25px;
    border: 8px solid hsl(0, 0%, 20%);
}

.landingpage .price {
    padding: var(--medspace);
    width: 220px;
    height: 400px;
    border-radius: 20px;
    background-color: var(--solid);
    display: inline-table;
    margin: var(--medspace);
}

.landingpage .price .tier {
    padding: var(--vbigspace);
    color: var(--textalt);
}

.landingpage .price .amount {
    font-size: 50px;
}

.landingpage .price .features {
    text-align: left;
}

.landingpage .email {
    color: inherit;
}

.profile-pic {
    width: 250px;
    max-width: calc(100vw - 2 * var(--medspace));
    border-radius: 50%;
    margin: var(--bigspace);
}

.landingpage .card {
    color: var(--textcolour);
    background-color: white;
    box-shadow: 0 0 10px 3px rgba(0,0,0,0.2);
    max-width: 300px;
    padding: var(--bigspace);
    border-radius: 15px;
}

.accented {
    background-color: var(--accent);
}

.black-text {
    color: var(--textcolour);
}

.text-accented {
    color: var(--accent);
}

.text-accented2 {
    color: var(--accent2);
}

.black-accent {
    background-color: var(--textcolour);
    color: var(--solid);
}

.footer-tablist-item {
    text-align: center;
    padding-bottom: 20px;
    cursor: pointer;
}

.footer-tablist-item:hover {
    color: var(--accent);
    font-weight: bold;
}

.link {
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    padding-left: 5px;
}

.covid-icon {
    width: 70px;
}

.squircle {
    border-radius: 30px;
    max-width: calc(100vw - 4 * var(--medspace));
}

.logo {
    max-width: calc(100vw - 4 * var(--medspace));
}

.home-icon {
    height: 70px;
}

.main-pic {
    width: 100vw;
    object-fit: cover;
    height: 600px;
    position: absolute;
    z-index: -1;
}

.white-text {
    color: white !important;
}

.about-image {
    width: 100vw;
    height: 400px;
    object-fit: cover;
}

.z1 {
    z-index: 1;
}

.z2 {
    z-index: 2;
}

div.home-image-space {
    height: 300px;
}

.img-text-combo {
    max-width: calc(100vw - 4 * var(--medspace));
    height: calc(var(--maincontent) * (9/16));
    width: var(--maincontent);
    position: relative;
    margin-bottom: 20px;
}

.img-text-combo-content {
    position: absolute;
    bottom: 0;
    padding: 20px;
    color: white;
}

.img-text-combo-image {
    max-width: calc(100vw - 4 * var(--medspace));
    height: calc(var(--maincontent) * (9/16));
    width: var(--maincontent);
    object-fit: cover;
    position: absolute;
    z-index: -1;
    -webkit-filter: brightness(0.8);
            filter: brightness(0.8);
}

.large-nav, .small-nav {
    width: 100%;
    height: var(--bigheight);
}

.large-nav .logo {
    width: var(--logoWidth);
    padding: var(--bigspace);
    cursor: pointer;
}

.small-nav .logo {
    width: var(--logoWidth);
    padding: var(--bigspace);
}

.hamburger > img{
    height: 20px;
    padding: 15px;
}

.large-nav .navactions {
    float: right;
    padding-right: var(--vbigspace);
    height: var(--bigheight);
}

.tab, .tablist-item {
    display: inline-block;
    padding: var(--medspace);
    cursor: pointer;
}

.tab:hover, .tab.selected-tab {
    font-weight: bold;
}

.tablist-item {
    display: block;
}

.large-nav .navactions button {
    margin-left: 0;
    margin-right: 0;
}

.tablist {
    padding-top: var(--bigspace);
    text-align: center;
}


