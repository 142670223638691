.edituser .registration {
    word-break: break-all;
}

.edituser td {
    padding: var(--medspace);
}

.edituser .email {
    padding: var(--medspace);
}
