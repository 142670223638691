.grid1x3 {
    display: grid;
    grid-template-areas: "a1" "a2" "a3";
    grid-template-rows: 1fr auto 1fr;
}

.grid1x3 > * {
    grid-area: a2;
}

.grid1x3 > a1, .grid1x3 > .a1 {grid-area: a1;}
.grid1x3 > a2, .grid1x3 > .a2 {grid-area: a2;}
.grid1x3 > a3, .grid1x3 > .a3 {grid-area: a3;}