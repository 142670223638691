body {
    --navwidth: 250px;
    --navOffset: 0px;
}

.home {
    display: grid;
    grid-template-columns: var(--bigheight) calc(var(--navwidth) - var(--bigheight)) auto;
    grid-template-rows: var(--bigheight) auto;
    grid-template-areas: 'burger title title' 'nav nav main';
    /* transition: var(--longanim); */
}

.home .titlebar {
    grid-area: title;
    width: 100%;
    height: 100%;
    background-color: var(--solid);
    border-bottom: var(--border);
    box-sizing: border-box;
}

.home .burgerarea {
    width: 100%;
    height: 100%;
    grid-area: burger;
    background-color: var(--solid);
    border-bottom: var(--border);
    box-sizing: border-box;
}

.home .hamburger {
    height: 20px;
    padding: 15px;
}

.home .burgerbutton {
    display: inline-block;
    height: var(--medheight);
    width: var(--medheight);
    border-radius: 50%;
    cursor: pointer;
    transition: var(--veryshortanim);
}

.home .burgerbutton:hover {
    background-color: var(--accent2);
}

.home .logo {
    width: 138px;
    padding: var(--bigspace);
}

.home .nav {
    grid-area: nav;
    padding-top: var(--medspace);
    transform: translate(var(--navOffset), 0px);
    transition: var(--shortanim);
    overflow-y: auto;
}

.home .navitem {
    margin: var(--medspace);
    margin-left: 0;
    padding-left: var(--bigspace);
    height: var(--medheight);
    border-radius: 0 100vw 100vw 0;
    cursor: pointer;
    transition: var(--veryshortanim);
}

.home .navitem:hover {
    background-color: var(--accent2);
}

.home .navitem.selected {
    background-color: var(--accent2);
    font-weight: bold;
}

.home .main {
    grid-area: main;
    padding: var(--bigspace);
    box-sizing: border-box;
    margin-left: var(--navOffset);
    transition: var(--shortanim);
    overflow-y: auto;
}

.home .maincontent {
    max-width: var(--maincontent);
    margin: auto;
    padding-bottom: var(--bigheight);
}

