/*noinspection CssUnknownTarget*/
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap');

* {
    /* colours */
    --bg: hsl(210, 17%, 98%);
    --solid: white;
    --textcolour: hsl(0, 0%, 14%);
    --textalt: hsl(0, 0%, 40%);
    --accent: hsl(43, 80%, 65%);
    --accent2: hsl(43, 100%, 90%);
    --accent3: hsl(43, 50%, 25%);
    --error: hsl(0, 100%, 30%);
    --buttontextcolour: var(--textcolour);
    
    /* borders */
    --radius: 8px;
    --border: 1px solid hsl(220, 9%, 87%);
    
    /* lengths */
    --hugespace: 50px;
    --vbigspace: 30px;
    --bigspace: 18px;
    --medspace: 10px;
    --smallspace: 5px;
    --bigheight: 80px;
    --medheight: 50px;
    --smallheight: 40px;
    --inputWidth: 300px;
    --maincontent: 800px;
    --logoWidth: 138px;

    /* animation */
    --longanim: 2s;
    --shortanim: 0.5s;
    --veryshortanim: 0.2s;

    font-family: 'Lexend Deca', sans-serif;
    font-size: medium;
}

label.input {
    display: block;
    color: var(--textcolour);
    padding: var(--medspace);
    cursor: pointer;
}

input.input, textarea.input {
    display: block;
    color: var(--textcolour);
    padding: var(--medspace);
    border: var(--border);
    border-radius: var(--radius);
    width: 100%;
    max-width: var(--inputWidth);
    box-sizing: border-box;
    transition: var(--shortanim);
    outline: none;
    font-family: 'Lexend Deca', sans-serif;
    font-size: medium;
    margin-bottom: var(--bigspace);
}

textarea.input {
    max-width: 100%;
}

/* input.input:invalid {
    border: 1px red solid;
} */

label.radio, label.checkbox {
    cursor: pointer;
    margin-right: var(--medspace);
}

input.radio, input.checkbox {
    margin-left: var(--medspace);
    margin-right: var(--smallspace);
    cursor: pointer;
}

span.radio, span.checkbox {
    display: inline-block;
}

.radiogroup, .checkboxgroup {
    margin-top: var(--bigspace);
    margin-bottom: var(--bigspace);
}

h1 {
    font-size: larger;
    font-weight: normal;
    color: var(--textcolour);
    /* padding-left: var(--medspace);
    padding-right: var(--medspace); */
}

h1.nopad {
    padding: 0;
}

h1.huge {
    font-size: xx-large;
}

h1.xhuge {
    font-size: 45px;
    color: inherit;
    margin: 0;
}

h1.xxhuge {
    font-size: 60px;
    color: inherit;
}

table td, table th {
    padding: var(--bigspace);
    text-align: left;
}

html {
    height: 100%;
    user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
}

body {
    overflow-x: hidden;
    height: 100%;
}

#root {
    height: 100vh;
}

.selectable {
    user-select: all; /* supported by Chrome and Opera */
   -webkit-user-select: all; /* Safari */
   -moz-user-select: all; /* Firefox */
   -ms-user-select: all; /* Internet Explorer/Edge */
}

.fullscreen {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    box-sizing: border-box;
}

.hidden {
    display: none;
    transition: 1s;
}

.maxwidth {
    width: 100%;
}

.autoscroll {
    overflow-y: auto;
}

.overflow-x {
    overflow-x: auto;
}

.noclick {
    pointer-events: none;
}

.centered {
    text-align: center;
}

.pointer {
    cursor: pointer;
}

.block {
    display: block;
}

.blocklink {
    cursor: pointer;
    text-decoration: underline;
    display: block;
    padding-top: var(--smallspace);
    padding-bottom: var(--smallspace);
}

.nowrap {
    white-space: nowrap;
}

.margin-auto {
    margin: auto;
}

.capitalize {
    text-transform: capitalize;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.underline {
    text-decoration: underline;
}

div.medspace {
    height: var(--medspace);
}

div.bigspace {
    height: var(--bigspace);
}

div.vbigspace {
    height: var(--vbigspace);
}

div.bigheight {
    height: var(--bigheight);
}

div.medheight {
    height: var(--medheight);
}

div.smallheight {
    height: var(--smallheight);
}

.absolute {
    position: absolute;
}

.shadow {
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.3);
}

.bigshadow {
    box-shadow: 0 3px 60px 0 rgba(0, 0, 0, 0.6)
}

.aligntop {
    vertical-align: top;
}

.justify {
    text-align: justify;
    text-justify: inter-word;
}

@media screen and (max-width: 400px) {
    .myaccount {
        width: initial;
    }

    .myaccount table {
        margin: initial;
    }
    
    .myaccount table td {
        display: block;
    }
    
    .edituser td {
        display: block;
    }
}

@media screen and (max-width: 600px) {
    .donationform td {
        display: block;
    }

    .hide-on-mobile {
        display: none !important;
    }
}

.side-by-side {
    display: grid;
    grid-template-areas: "left right";
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    align-content: center;
    justify-content: center;
}

.side-by-side > .left-half {
    grid-area: left;
    padding: var(--medspace);
}

.side-by-side > .right-half {
    grid-area: right;
    padding: var(--medspace);
}

@media screen and (max-width: 800px) {
    .side-by-side {
        grid-template-areas: "left" "right";
        grid-template-columns: auto;
        grid-template-rows: auto auto;
        margin: auto;
    }

    .side-by-side > .left-half {
        max-width: 400px;
    }

    .side-by-side > .right-half {
        max-width: 400px;
    }
}

@media screen and (max-width: 850px) {
    /* if width changed also change js in purefunctions.tsx */
    .home .main {
        min-width: 100vw;
    }
}

.small-nav {
    display: none;
}

.large-nav {
    display: initial;
}

@media screen and (max-width: 900px) {
    .small-nav {
        display: initial;
    }

    .large-nav {
        display: none;
    }
}

.error {
    padding: 10px;
    border-radius: 10px;
}
