.large-nav, .small-nav {
    width: 100%;
    height: var(--bigheight);
}

.large-nav .logo {
    width: var(--logoWidth);
    padding: var(--bigspace);
    cursor: pointer;
}

.small-nav .logo {
    width: var(--logoWidth);
    padding: var(--bigspace);
}

.hamburger > img{
    height: 20px;
    padding: 15px;
}

.large-nav .navactions {
    float: right;
    padding-right: var(--vbigspace);
    height: var(--bigheight);
}

.tab, .tablist-item {
    display: inline-block;
    padding: var(--medspace);
    cursor: pointer;
}

.tab:hover, .tab.selected-tab {
    font-weight: bold;
}

.tablist-item {
    display: block;
}

.large-nav .navactions button {
    margin-left: 0;
    margin-right: 0;
}

.tablist {
    padding-top: var(--bigspace);
    text-align: center;
}

