.addressfinder {
    margin-bottom: var(--medspace);
}

.addressfinder .link {
    text-decoration: underline;
    cursor: pointer;
    padding: var(--medspace);
}

.addressfinder .message {
    padding: var(--medspace);
}
