.login {
    width: calc(var(--inputWidth) + 2 * var(--bigspace));
    max-width: 100vw;
    padding: var(--bigspace);
    border: var(--border);
    border-radius: 10px;
    background-color: var(--solid);
    box-sizing: border-box;
    transition: var(--longanim);
}

.login-logo {
    width: 200px;
    max-width: 100%;
    padding: var(--hugespace);
}

.forgot {
    text-decoration: underline;
    cursor: pointer;
    padding: var(--smallspace);
}

.login .error {
    color: var(--error);
}
