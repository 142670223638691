.accountcircle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: var(--bigspace);
    background-color: var(--accent2);
    cursor: pointer;
}

.accountletter {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 70px;
    cursor: pointer;
}

