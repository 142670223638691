.userguide {
    max-width: 600px;
    width: 100vw;
    padding: var(--medspace);
    padding-top: var(--hugespace);
    padding-bottom: var(--hugespace);
    box-sizing: border-box;
    word-break: break-word;
}

.userguide img {
    width: 100%;
    padding: var(--bigspace);
    box-sizing: border-box;
}
