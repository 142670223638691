.landingpage {
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.landingpage .body > * {
    position: relative;
}

.landingpage .body .centre {
    max-width: var(--maincontent);
    margin: auto;
    padding: var(--bigspace);
}

.landingpage .halfwidth {
    max-width: calc(var(--maincontent) / 2);
    display: inline-block;
}

.landingpage .image {
    position: absolute;
    width: 100vw;
    background-repeat: no-repeat;
    background-attachment: local;
    background-position: center;
}

.landingpage .image.main {
    margin-top: 100px;
    height: 700px;
    background-size: 1000px;
}

.landingpage .image.bg1 {
    height: 700px;
    background-size: 4000px;
}

.landingpage .image.bg2 {
    height: 930px;
    background-size: 4000px;
}

.landingpage .image.bg3 {
    margin-top: -1px;
    height: 130px;
    background-size: 4000px;
}

.landingpage .image.bg4 {
    margin-top: -1px;
    height: 160px;
    background-size: 4000px;
}

.landingpage .image.bg5 {
    margin-top: -5px;
    height: 470px;
    background-size: 4000px;
}

.landingpage .image.bg6 {
    margin-top: -100px;
    height: 460px;
    background-size: 4000px;
}

.landingpage .image.bg7 {
    height: 1020px;
    background-size: 4000px;
}

/* main picture */
.landingpage .body .space1 {
    height: 750px;
}

/* devices */
.landingpage .body .space2 {
    height: 630px;
}

.landingpage .body .space3 {
    height: 120px;
}

.landingpage .body .space4 {
    height: 130px;
}

.landingpage .body .space5 {
    height: 50px;
}

.landingpage .body .space6 {
    height: 350px;
}

.landingpage .body .dark {
    background-color: var(--textcolour);
    color: var(--bg);
}

.landingpage .body .accented {
    background-color: var(--accent);
}

.landingpage .box {
    height: 200px;
    width: 300px;
    display: inline-grid;
    margin: var(--medspace);
    grid-template-rows: auto max-content;
}

.landingpage .box .icon {
    margin: auto;
    width: 100px;
}

.landingpage .box .text {
    text-align: center;
}

.landingpage .devices {
    max-width: 500px;
    margin: auto;
}

.landingpage img.tablet {
    position: absolute;
    margin-left: 60px;
    width: 400px;
    border-radius: 25px;
    border: 15px solid hsl(0, 0%, 20%);
}

.landingpage img.phone {
    position: absolute;
    margin-top: 130px;
    width: 200px;
    border-radius: 25px;
    border: 8px solid hsl(0, 0%, 20%);
}

.landingpage .price {
    padding: var(--medspace);
    width: 220px;
    height: 400px;
    border-radius: 20px;
    background-color: var(--solid);
    display: inline-table;
    margin: var(--medspace);
}

.landingpage .price .tier {
    padding: var(--vbigspace);
    color: var(--textalt);
}

.landingpage .price .amount {
    font-size: 50px;
}

.landingpage .price .features {
    text-align: left;
}

.landingpage .email {
    color: inherit;
}

.profile-pic {
    width: 250px;
    max-width: calc(100vw - 2 * var(--medspace));
    border-radius: 50%;
    margin: var(--bigspace);
}

.landingpage .card {
    color: var(--textcolour);
    background-color: white;
    box-shadow: 0 0 10px 3px rgba(0,0,0,0.2);
    max-width: 300px;
    padding: var(--bigspace);
    border-radius: 15px;
}

.accented {
    background-color: var(--accent);
}

.black-text {
    color: var(--textcolour);
}

.text-accented {
    color: var(--accent);
}

.text-accented2 {
    color: var(--accent2);
}

.black-accent {
    background-color: var(--textcolour);
    color: var(--solid);
}

.footer-tablist-item {
    text-align: center;
    padding-bottom: 20px;
    cursor: pointer;
}

.footer-tablist-item:hover {
    color: var(--accent);
    font-weight: bold;
}

.link {
    display: inline-block;
    cursor: pointer;
    text-decoration: underline;
    padding-left: 5px;
}

.covid-icon {
    width: 70px;
}

.squircle {
    border-radius: 30px;
    max-width: calc(100vw - 4 * var(--medspace));
}

.logo {
    max-width: calc(100vw - 4 * var(--medspace));
}

.home-icon {
    height: 70px;
}

.main-pic {
    width: 100vw;
    object-fit: cover;
    height: 600px;
    position: absolute;
    z-index: -1;
}

.white-text {
    color: white !important;
}

.about-image {
    width: 100vw;
    height: 400px;
    object-fit: cover;
}

.z1 {
    z-index: 1;
}

.z2 {
    z-index: 2;
}

div.home-image-space {
    height: 300px;
}

.img-text-combo {
    max-width: calc(100vw - 4 * var(--medspace));
    height: calc(var(--maincontent) * (9/16));
    width: var(--maincontent);
    position: relative;
    margin-bottom: 20px;
}

.img-text-combo-content {
    position: absolute;
    bottom: 0;
    padding: 20px;
    color: white;
}

.img-text-combo-image {
    max-width: calc(100vw - 4 * var(--medspace));
    height: calc(var(--maincontent) * (9/16));
    width: var(--maincontent);
    object-fit: cover;
    position: absolute;
    z-index: -1;
    filter: brightness(0.8);
}
