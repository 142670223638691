.edit-assessment a {
    text-decoration: none;
}

.assessment-picture {
    width: 500px;
    max-width: 100%;
    display: block;
    border-radius: 10px;
}

.open-document {
    border: 1px solid black;
    padding: 20px;
    border-radius: 10px;
    color: black;
}
