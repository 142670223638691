.grid3x1 {
    display: grid;
    grid-template-areas: "a1 a2 a3";
    grid-template-columns: 1fr auto 1fr;
}

.grid3x1 > * {
    grid-area: a2;
}

.grid3x1 > a1, .grid3x1 > .a1 {grid-area: a1;}
.grid3x1 > a2, .grid3x1 > .a2 {grid-area: a2;}
.grid3x1 > a3, .grid3x1 > .a3 {grid-area: a3;}