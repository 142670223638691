.card {
    margin-bottom: var(--bigspace);
}

.card .solid {
    padding: var(--bigspace);
    background-color: var(--solid);
    border: var(--border);
    border-top: none;
    border-radius: 0 0 var(--radius) var(--radius);
}

.card .solid .middle {
    width: fit-content;
    max-width: 100%;
    margin: auto;
    overflow-x: auto;
}

.card .top {
    background-color: var(--accent2);
    padding: var(--bigspace);
    border: var(--border);
    border-radius: var(--radius) var(--radius) 0 0;
}

.card .top h1 {
    margin: 0;
    color: var(--textcolour);
}

