.connectiondetector {
    position: fixed;
    pointer-events: none;
    width: 100%;
}

.connectiondetector .text {
    text-align: center;
    width: 100%;
    max-width: 300px;
    padding: var(--bigspace);
    background-color: var(--accent2);
    border: var(--border);
    border-radius: var(--radius);
    margin: var(--bigspace);
    box-sizing: border-box;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
}
