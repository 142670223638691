.registeruser {
    padding: var(--bigspace);
    background-color: var(--solid);
    border: var(--border);
    border-radius: var(--radius);
    max-width: 100vw;
    width: calc(var(--inputWidth) + 2 * var(--bigspace));
    box-sizing: border-box;
}

.registeruser-error {
    max-width: var(--inputWidth);
    padding: var(--medspace);
}
