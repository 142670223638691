.userlist {
    padding: var(--bigspace);
    background-color: var(--solid);
    border: var(--border);
    border-radius: var(--radius);
    overflow-x: auto;
}

.manageusers tbody tr:hover {
    background-color: var(--accent2);
    cursor: pointer;
    transition: var(--veryshortanim);
}

.manageusers .edit img {
    height: 16px;
}

.manageusers td, .manageusers th {
    white-space: nowrap;
}