.title-with-back img {
    width: 12px;
    padding-right: var(--smallspace);
}

.title-with-back h1 {
    display: inline-block;
}

.title-with-back {
    cursor: pointer;
}

.title-with-back:hover {
    font-weight: bolder;
}
