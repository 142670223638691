.donationform {
    max-width: var(--maincontent);
    padding-top: var(--hugespace);
    padding-bottom: var(--bigheight);
    margin: var(--bigspace);
}

.donationform table.nopad td {
    padding: 0;
}

.donationform table input.input {
    margin: 0;
}

.donationform table h1 {
    margin-right: var(--bigspace);
}

.donationform .line {
    background-color: var(--accent);
    height: 1px;
    margin-top: var(--bigspace);
    margin-bottom: var(--bigspace);
}
