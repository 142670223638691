.changepassword {
    padding: var(--medspace);
}

.changepasswordinputs {
    padding: var(--bigspace);
    background-color: var(--solid);
    border: var(--border);
    border-radius: var(--radius);
}

.changepasswordinputs > * {
    margin: auto;
    width: fit-content;
}

.changepassword .error {
    color: var(--error);
    padding: var(--smallspace);
}
